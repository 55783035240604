#header {
	.box-content {
		position: relative;

		.box-top {
			position: relative;
			padding: 7px 0;
			color: #ffffff;
			text-align: right;
			background-color: $cprimary;

			span {
				font-weight: 500;
			}

			.icon {
				margin-right: 2px;
			}

			.b-phones {
				display: inline-block;
				margin-right: 28px;

				span {
					margin-right: 10px;
				}
			}

			.b-email {
				display: inline-block;
			}
		}

		.box-menu {
			position: relative;
			z-index: 15;
			box-shadow: 0 3px 4px rgba(0,0,0,0.10);

			.navbar {
				background-color: #ffffff !important;

				.navbar-brand {
					img {
						height: 94px;
					}
				}

				.navbar-toggler {
					color: $cprimary;
					font-size: 1.9rem;
					border: 0;
				}

				#nav-collapse {
					.navbar-nav {
						.simple-item {
							.nav-link {
								color: $csecondary;
								font-size: 1.20rem;
								font-weight: 500;

								&:hover {
									color: $cprimary;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.box-menu {
				.navbar {
					#nav-collapse {
						.navbar-nav {
							.simple-item {
								position: relative;
								padding-left: 0.8vw;
								padding-right: 0.8vw;

								&:before {
									content: " ";
									position: absolute;
									top: calc(50% - 9px);
									right: 0;
									height: 18px;
									border-right: 2px solid #c1c8ca;
								}

								&:last-child {
									&:before {
										display: none;
									}
								}
							}
						}
					}
				}
			}

			@media screen and (max-width: 1199px) {
				.box-menu {
					.navbar {
						#nav-collapse {
							.navbar-nav {
								.simple-item {
									.nav-link {
										font-size: 1.10rem;
									}
								}
							}
						}
					}
				}
			}
			@media screen and (max-width: 1399px) {
				.box-menu {
					.navbar {
						.navbar-brand {
							img {
								height: 80px;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.box-top {
				text-align: center;

				.b-phones {
					margin-right: 0;
				}
			}

			.box-menu {
				.navbar {
					.navbar-brand {
						img {
							height: 42px;
						}
					}

					#nav-collapse {
						.navbar-nav {
							.simple-item {
								.nav-link {
									padding-top: 0.25rem;
									padding-bottom: 0.25rem;
									font-size: 1.1rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 575px) {
			.box-top {
				font-size: 0.80rem;

				span {
					font-size: 0.80rem;
				}
			}
		}
	}
}
