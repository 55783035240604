.btn-s1 {
  padding: 0.5rem 0.75rem;
  color: #ffffff !important;
  font-size: 1.35rem;
  border: 0 !important;
  border-radius: 0;
  background-color: $csecondary;

  &:hover, &:focus, &:active {
    border-color: #282828 !important;
    background-color: $csecondary2 !important;
  }
}
