// Fonts
// @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.f-w-300 {
  font-weight: 300 !important;
}
.f-w-400 {
  font-weight: 400 !important;
}
.f-w-500 {
  font-weight: 500 !important;
}
.f-w-600 {
  font-weight: 600 !important;
}
.f-w-700 {
  font-weight: 700 !important;
}
.f-w-800 {
  font-weight: 800 !important;
}
