#footer {
  position: relative;
  padding: 22px 0 35px 0;
  margin-top: 45px;
  z-index: 15;
  background-color: $cprimary;

  &:before {
    content: " ";
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 41px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/footer-line.svg');
  }

  >.container {
    .row {
      align-items: center;
    }
  }

  .col-text {
    color: #ffffff;

    .title {
      margin-bottom: 8px;
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 1.30rem;
    }

    strong {
      font-weight: 600;
    }
  }

  .col-logo {
    .logo {
      width: 230px;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 10px 0 20px 0;
    text-align: center;

    .col-logo {
      margin-top: 16px;
    }
  }
  @media screen and (max-width: 991px) {
    .col-text {
      .title {
        margin-bottom: 4px;
      }

      &:nth-child(1) {
        margin-bottom: 16px;
      }
    }
  }
}
