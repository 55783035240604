#impresion-page {
  border-top: 1px solid #d5cfcf;

  .title-2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .main-section {
    position: relative;

    .box-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 52vw;
      height: 100%;
      background-image: url('../images/pages/impresion/image-1.jpg');
    }

    >.container {
      >.row {
        align-items: center;
        min-height: 81vh;
      }
    }

    .col-image {
      position: relative;
    }

    .col-text {
      color: #817f7f;
      font-size: 1.20rem;
      font-weight: 500;
      line-height: 1.70;

      .title-s1 {
        font-weight: 500;
        letter-spacing: 1px;

        strong {
          font-weight: 800;
        }
      }

      .subtitle {
        color: $cprimary;
        font-weight: 600;
      }
    }

    @media screen and (max-width: 991px) {
      .box-image {
        width: 100vw;
        opacity: 0.1;
      }

      .col-image {
        display: none;
      }
    }
    @media screen and (max-width: 575px) {
      padding-bottom: 20px;

      .col-text {
        font-size: 0.95rem;
        text-align: center;
      }
    }
  }

  .vision-section {
    position: relative;
    width: 100%;
    background-color: $csecondary;
    z-index: 5;

    .box-information {
      padding-top: 28px;
      padding-bottom: 35px;
      margin-bottom: -50px;
      color: #ffffff;

      p {
        font-size: 1.05rem;
        line-height: 1.4;
      }

      .p-md {
        font-size: 1.25rem;
      }

      .box-table {
        position: relative;
        margin-top: 20px;

        table {
          color: #ffffff;

          thead {
            tr {
              th, td {
                width: 12.5%;
                font-size: 0.9rem;
                font-weight: 600;
                line-height: 1.15;
                text-align: center;
                vertical-align: middle;
              }
            }
          }

          tbody {
            tr {
              td, th {
                text-align: center;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      .box-information {
        padding-bottom: 35px;

        p {
          font-size: 0.95rem;
        }
      }
    }
  }

}
