#microcorrugados-page {
  border-top: 1px solid #d5cfcf;

  .title-2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .main-section {
    position: relative;

    .box-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 52vw;
      height: 100%;
      background-image: url('../images/pages/microcorrugados/image.jpg');
    }

    >.container {
      >.row {
        align-items: center;
        min-height: 81vh;
      }
    }

    .col-image {
      position: relative;
    }

    .col-text {
      color: #817f7f;
      font-size: 1.10rem;
      font-weight: 500;
      line-height: 1.50;

      .title-s1 {
        font-weight: 500;
        letter-spacing: 1px;

        strong {
          font-weight: 800;
        }
      }

      .subtitle {
        color: $cprimary;
        font-weight: 600;
      }
    }

    @media screen and (max-width: 991px) {
      .box-image {
        width: 100vw;
        opacity: 0.1;
      }

      .col-image {
        display: none;
      }
    }
    @media screen and (max-width: 575px) {
      padding-bottom: 20px;

      .col-text {
        font-size: 0.95rem;
        text-align: center;
      }
    }
  }

  .vision-section {
    position: relative;
    width: 100%;
    background-color: $csecondary;
    z-index: 5;

    .box-information {
      padding-top: 28px;
      padding-bottom: 35px;
      margin-bottom: -50px;
      color: #ffffff;

      p {
        font-size: 1.05rem;
        line-height: 1.4;
      }

      .row-info {
        padding-top: 34px;
        padding-bottom: 40px;

        .col-info {
          padding-top: 14px;
          padding-bottom: 22px;
          text-align: center;

          .b-image {
            display: block;
            margin-bottom: 15px;
            text-align: center;
            line-height: 1.0;

            .image {
              display: inline-block;
              width: 400px;
              max-width: 79%;
              height: 230px;
              border-radius: 22px;
              border: 1px solid rgba(255,255,255,0.7);
              background-color: #ffffff;
            }
          }

          .title {
            position: relative;
            margin-bottom: 10px;
            font-size: 1.30rem;
            font-weight: 600;
            letter-spacing: 3px;
            text-transform: uppercase;
          }
        }
      }
    }

    @media screen and (min-width: 992px) {
      .box-information {
        .row-info {
          .col-info {
            &:nth-child(1)  {
              // padding-right: 62px;
              border-right: 1px solid #ffffff;
            }
            &:nth-child(2) {
              // padding-right: 62px;
              border-right: 1px solid #ffffff;
            }
            &:last-child {
              // padding-left: 62px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1499px) {
      .box-information {
        .row-info {
          .col-info {
            .title {
              font-size: 1.15em;
            }

            p {
              font-size: 0.95rem;
            }
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      .box-information {
        padding-bottom: 15px;

        p {
          font-size: 0.95rem;
        }

        .row-info {
          padding-top: 2px;

          .col-info {
            .b-image {
              .image {
                max-width: 90%;
                width: 320px;
                height: 320px;
                border-radius: 50%;
              }
            }

            .title {
              margin-bottom: 5px;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px) {
      .box-information {
        .row-info {
          .col-info {
            .b-image {
              .image {
                width: 290px;
                height: 290px;
              }
            }
          }
        }
      }
    }
  }

}
