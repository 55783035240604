#home-page {

	// == Banners ==
	.banner-section{
		position: relative;
		padding: 0;
		box-shadow: 0 3px 5px rgba(0,0,0,0.3);

		.swiper-container{
			// box-shadow: 0 3px 5px rgba(0,0,0,0.4);

			.swiper-wrapper{
				.swiper-slide{
					background-color: $soft-gray;

					img{
						width: 100%;
						// display: block;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		@media screen and (max-width: 991px) {
			.swiper-desktop{
				display: none;
			}
			.swiper-mobile{
				display: block;
			}
		}
	}
	// == ==

	// == About ==
	.about-section {
		padding: 54px 0 42px 0;

		.container {
			>.row {
				align-items: center;
			}
		}

		.col-info {
			text-align: center;

			&.c2 {
				margin-bottom: 40px;
			}

			.box-image {
				display: block;
				margin-bottom: 15px;

				.image {
					display: inline-block;
					width: 300px;
					height: 300px;
					border: 1px solid $csecondary;
					border-radius: 50%;
					background-color: $soft-gray;
				}
			}

			.title {
				margin-bottom: 5px;
				color: $csecondary;
				font-size: 1.95rem;
				font-weight: 600;
			}

			p {
				color: #3e3f40;
				font-size: 1.00rem;
				font-weight: 500;
			  line-height: 1.50;
				text-align: justify;
			}
		}

		@media screen and (min-width: 1200px) {
			.col-info {
				padding-left: 22px;
				padding-right: 22px;
			}
		}
		@media screen and (min-width: 992px) {
			>.container {
				padding-left: 0;
				padding-right: 0;
			}
		}
		@media screen and (max-width: 991px) {
			padding: 40px 0 15px 0;

			.col-info {
				margin-bottom: 22px;

				.box-image {
					margin-bottom: 2px;
				}

				.title {
					font-size: 1.65rem;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	// == ==

}
