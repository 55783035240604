#contact-page {

  .main-section {
    position: relative;
    width: 100%;
    margin-bottom: -54px;
    overflow: hidden;

    .container {
      >.row {
        margin-top: -98px;
        align-items: center;
      }
    }

    .box-title {
      position: relative;
      display: inline-block;
      padding-top: 28px;
      padding-bottom: 25px;
      height: 98px;
      width: 100%;
      background-color: #efefef;

      &:before {
        content: " ";
        position: absolute;
        bottom: 0;
        left: -30vw;
        width: 30vw;
        height: 98px;
        background-color: #efefef;
      }

      &:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60vw;
        height: 98px;
        background-color: #efefef;
      }

      &.wd-100 {
        &:after {
          width: 100vw;
        }
      }

      .title-s1 {
        position: relative;
        z-index: 5;
      }
    }

    .col-form {
      padding-top: 120px;
      padding-bottom: 70px;
    }

    .col-image {
      .img {
        position: relative;
        width: 43vw;
        height: 82vh;
        min-height: 590px;
        border-left: 2px solid #4a4949;
        background-color: $soft-gray;
      }
    }

    @media screen and (max-width: 1199px) {
      .col-image {
        .img {
          width: 52vw;
        }
      }
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 0;

      .box-title {
        &:after {
          width: 110vw;
        }
      }

      .col-form {
        padding-bottom: 20px;
      }

      .col-image {
        display: none;
      }
    }
  }

}
