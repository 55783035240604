#about-us-page {
  border-top: 1px solid #d5cfcf;

  .title-2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .main-section {
    position: relative;
    margin-bottom: -40px;

    .box-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 54vw;
      height: 100%;
      background-image: url('../images/pages/about-us/image-1.jpg');
    }

    >.container {
      >.row {
        align-items: center;
        min-height: 85vh;
      }
    }

    .col-image {
      position: relative;
    }

    .col-text {
      padding-top: 30px;
      padding-bottom: 45px;
      color: #817f7f;
      font-size: 1.10rem;
      font-weight: 500;
      line-height: 1.50;

      .title-s1 {
        font-weight: 500;
        letter-spacing: 1px;

        strong {
          font-weight: 800;
        }
      }

      .subtitle {
        color: $cprimary;
        font-weight: 600;
      }
    }

    @media screen and (max-width: 991px) {
      .box-image {
        width: 100vw;
        opacity: 0.1;
      }

      .col-image {
        display: none;
      }
    }
    @media screen and (max-width: 575px) {
      padding-bottom: 20px;

      .col-text {
        font-size: 0.95rem;
        text-align: center;
      }
    }
  }

}
